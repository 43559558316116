import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userData: null,
    monitorPanelShow: true,
    showVideo: true,
    showBotones: false,
    showTracking: true,
    alarmSound: localStorage.alarmSound && JSON.parse(localStorage.alarmSound) ? true : false,
    autoVideoOnAlert: localStorage.autoVideoOnAlert && JSON.parse(localStorage.autoVideoOnAlert) ? true : false,
    autoVideoRecordOnAlert: localStorage.autoVideoRecordOnAlert && JSON.parse(localStorage.autoVideoRecordOnAlert) ? true : false,
    toCallData: null,
    showUserModalSearch: false,
    reportData: null,
    grillaMonitoreo: false,
    monitoreoGrillaCounter: 0,
    soloTrackingUser: null,
    contentInfoUser: null,
    trackingInBigMap: null,
    mapCenter: {center: { lat: -33.522813209656135, lng: -70.69814623011989 }, zoom: 12 },
    usersInMonitoreo: [],
    primaryDB: null,
    monitoreoDB: null,
    trackingDB: null,
    uploadsInProgress: [],
    videoCallData: {
      status: false,
      alertID: null,
      geoUserAlert: null,
      fullView: true,
      deviceID: null,
      calledUserID: null,
      socketUrl: null,
      socketRoom: null,
      videocallID: null,
      hideVideo: false,
      silent: false
    },
		tuyaCameras: [],
		complaintTypes: null,
    complaintType: null,
		complaintsInMap: null,
		showingComplaint: null,
    videocallSoloConnected: false,
    configClick2call: {},
    configTwitter: {},
    configCompany: {},
		complaintTypesHeader: [],

    audioInputDevice: 'default',
    audioOutputDevice: 'default',
    modalAudioSettings: false
  },
  mutations: {
    setVideocallSoloConnected (state, value) {
      state.videocallSoloConnected = value
    },
    setComplaintsInMap (state, complaintsInMap) {
      state.complaintsInMap = complaintsInMap
    },
    setShowingComplaint (state, showingComplaint) {
      state.showingComplaint = showingComplaint
    },
    setComplaintTypes (state, complaintTypes) {
      state.complaintTypes = complaintTypes
    },
    setComplaintType (state, complaintType) {
      state.complaintType = complaintType
    },
    setUserData (state, user) {
      state.userData = user
    },
    setPanelShow (state, value) {
      state.monitorPanelShow = value
    },
    setShowVideo (state, value) {
      state.showVideo = value
    },
    setShowBotones (state, value) {
      state.showBotones = value
    },
    setShowTracking (state, value) {
      state.showTracking = value
    },
    setAlarmSound (state, value) {
      localStorage.alarmSound = value;
      state.alarmSound = value
    },
    setCallData (state,value) {
      state.toCallData = value
    },
    setShowUserModalSearch(state,value){
      state.showUserModalSearch = value
    },
    setReportECGData(state,value){
      state.reportData = value
    },
    toogleGrilla(state,value){
      state.grillaMonitoreo = value
    },
    setMonitoreoGrillaCounter(state,value){
      state.monitoreoGrillaCounter = value
    },
    setSoloTrackingUser (state,value) {
      state.soloTrackingUser = value
    },
    setTrackingInBigMap (state,value) {
      state.trackingInBigMap = value
    },
    setMapCenter (state,value) {
      state.mapCenter = value
    },
    setContentInfoUser (state,value) {
      state.contentInfoUser = value
    },
    setUsersInMonitoreo (state,value) {
      state.usersInMonitoreo = value
    },
    setPrimaryDB (state,value) {
      state.primaryDB = value
    },
    setMonitoreoDB (state,value) {
      state.monitoreoDB = value
    },
    setTrackingDB (state,value) {
      state.trackingDB = value
    },
    setUploadsInProgress (state, value) {
      state.uploadsInProgress = value
    },
    setAutoVideoOnAlert (state, value) {
      localStorage.autoVideoOnAlert = value;
      state.autoVideoOnAlert = value
    },
    setAutoVideoRecordOnAlert (state, value) {
      localStorage.autoVideoRecordOnAlert = value;
      state.autoVideoRecordOnAlert = value
    },
    setVideoCallData (state, value) {
      state.videoCallData = value
    },
		setTuyaCameras (state, value) {
      state.tuyaCameras = value
    },
    setConfigClick2call (state, value) {
      state.configClick2call = value
    },
    setConfigTwitter (state, value) {
      state.configTwitter = value
    },
    setConfigCompany (state, value) {
      state.configCompany = value
    },
    setComplaintTypesHeader (state, value) {
      state.complaintTypesHeader = value
    },
    setAudioInputDevice (state, value) {
      state.audioInputDevice = value
    },
    setAudioOutputDevice (state, value) {
      state.audioOutputDevice = value
    },
    setModalAudioSettings (state, value) {
      state.modalAudioSettings = value
    },
  },
  getters: {
    toCallData: state => {
      return state.toCallData
    },
    userData: state => {
      return state.userData
    },    
    soloTrackingUser: state => {
      return state.soloTrackingUser
    },
    trackingInBigMap: state => {
      return state.trackingInBigMap
    },
    reportData: state => {
      return state.reportData
    },
    configClick2call: state => {
    return state.configClick2call
  },
    configTwitter: state => {
    return state.configTwitter
  },
    configCompany: state => {
    return state.configCompany
  }
}
})

export default store