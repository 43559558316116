<template>
  <div>
    <CModal
      title="Botón SOS"
      color="primary"
      class="modal-alerta"
      :show="!!$store.state.showingComplaint"
    >
      <template #header>
        <p class="mb-0 text-center w-100 h5">Detalle Denuncia</p>
        <CButton class="mx-2 p-0" @click="closeModal()">
          <p class="text-white mb-0 font-weight-bold">X</p>
        </CButton>
      </template>
      <CRow>
        <CCol col="12" xl="12">
          <CRow>
            <CCol col="12" xl="12">
              <div class="border-primary px-0 rounded">
                <CRow>
                  <CCol col="12" xl="4">
                    <div class="border-primary px-0 m-2 rounded">
                      <div
                        class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top"
                      >
                        <p class="mb-0 ">Información del usuario</p>
                      </div>
                      <CRow class="p-3">
                        <CCol class="d-flex" col="12" xl="12">
                          <div class="mr-4">
                            <img
                              v-if="
                                $store.state.showingComplaint.user &&
                                  $store.state.showingComplaint.user.avatar_link
                              "
                              :src="
                                $store.state.showingComplaint.user.avatar_link
                              "
                              class="c-mb-2 avatar-alerta-user"
                            />
                            <div class="icon-alerta-user" v-else>
                              <font-awesome-icon icon="user" />
                            </div>
                          </div>
                          <div>
                            <p
                              class="mb-0 text-primary font-weight-bold"
                              v-if="$store.state.showingComplaint.user"
                            >
                              {{ $store.state.showingComplaint.user.name }}
                              {{ $store.state.showingComplaint.user.lastname }}
                            </p>
                            <p
                              class="mb-0 text-primary font-weight-bold"
                              v-else
                            >
                              Usuario no registrado
                            </p>
                            <!-- <p class="mb-0 text-primary d-flex align-items-center">
															<CImg
																class="mr-2"
																height="12"
																:src="require('@/assets/imgicons/ICONOS SOSRecurso 22@2x.png')"
															/>
															{{$store.state.showingComplaint.email}}
														</p> -->
                            <p
                              class="mb-0 text-primary d-flex align-items-center"
                              v-if="$store.state.showingComplaint.user"
                            >
                              <CImg
                                class="mr-2"
                                height="12"
                                :src="
                                  require('@/assets/imgicons/ICONOS SOSRecurso 20@2x.png')
                                "
                              />{{ $store.state.showingComplaint.user.phone }}
                              <CButton
                                :disabled="
                                  $store.state.toCallData ? true : false
                                "
                                color="primary"
                                class="ml-2 my-1"
                                @click="
                                  call(
                                    $store.state.showingComplaint.user.phone,
                                    $store.state.showingComplaint.user.name +
                                      ' ' +
                                      $store.state.showingComplaint.user
                                        .lastname
                                  )
                                "
                                size="sm"
                              >
                                <img
                                  style="margin-right: 5px;"
                                  class="white-color-image-overlay"
                                  height="12"
                                  :src="
                                    require('@/assets/imgicons/ICONOS SOSRecurso 20@2x.png')
                                  "
                                />
                                Llamar
                              </CButton>
                            </p>
                            <p
                              class="mb-0 text-primary d-flex align-items-center"
                              v-if="$store.state.showingComplaint.user"
                            >
                              <CImg
                                class="mr-2"
                                height="12"
                                :src="
                                  require('@/assets/imgicons/ICONOS SOSRecurso 21@2x.png')
                                "
                              />{{ $store.state.showingComplaint.user.address }}
                            </p>
                          </div>
                        </CCol>
                      </CRow>
                    </div>
                    <div class="border-primary px-0 m-2 rounded">
                      <div
                        class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top"
                      >
                        <p class="mb-0 ">Información de la denuncia</p>
                      </div>
                      <div class="p-3">
                        <p
                          class="mb-1 d-flex align-items-center justify-content-between font-weight-bold"
                        >
                          Identificador denuncia:
                          <span class="text-primary">{{
                            $store.state.showingComplaint.unique_id
                          }}</span>
                        </p>
                        <p class="mb-3 d-flex align-items-center justify-content-between font-weight-bold">
                          Tipo denuncia:
                          <span class="text-primary">{{$store.state.showingComplaint.type.title}}</span>
                        </p>
                        <p v-if="$store.state.showingComplaint?.address?.address" class="mb-3 d-flex justify-content-between font-weight-bold">
                          Ubicación:
                          <span class="text-primary" style="text-align: right !important;">{{$store.state.showingComplaint?.address?.address}}</span>
                        </p>
                        <GmapMap
                          :zoom="12"
                          :center="{
                            lat:
                              $store.state.showingComplaint.location.latitude,
                            lng:
                              $store.state.showingComplaint.location.longitude,
                          }"
                          :options="{
                            streetViewControl: false,
                            mapTypeControl: false,
                            zoomControl: true,
                          }"
                          class="map-container"
                          style="height: 150px; width: 100%;"
                        >
                          <GmapMarker
                            :position="{
                              lat:
                                $store.state.showingComplaint.location.latitude,
                              lng:
                                $store.state.showingComplaint.location
                                  .longitude,
                            }"
                            :clickable="true"
                            customInfo="Work"
                            :icon="{
                              url: returnIcon($store.state.showingComplaint),
                              scaledSize: { width: 40, height: 68 },
                            }"
                            :zIndex="3"
                          />
                        </GmapMap>
                        <div class="mt-2 w-100">
                          <p
                            class="mt-2 mb-1 text-primary d-flex align-items-center font-weight-bold"
                          >
                            Cambios de estado
                          </p>
                          <div>
                            <CDataTable
                              hover
                              striped
                              size="sm"
                              :items="$store.state.showingComplaint.status"
                              :fields="[
                                { key: 'status', label: 'Estado' },
                                { key: 'modified', label: 'Detalle' },
                                { key: 'date', label: 'Fecha' },
                              ]"
                              index-column
                            >
                              <template #status="data">
                                <td>
                                  <CBadge
                                    :color="getBadge(data.item.status_id)"
                                  >
                                    {{ data.item.status.name }}
                                  </CBadge>
                                </td>
                              </template>
                              <template #modified="data">
                                <td>
                                  <p class="mb-0">{{ data.item.details }}</p>
                                  <small>
                                    <font-awesome-icon icon="user" />
                                    {{
                                      data.item.modifier_user
                                        ? data.item.modifier_user.email
                                        : "--"
                                    }}
                                  </small>
                                </td>
                              </template>
                              <template #date="data">
                                <td class="text-right">
                                  <small>{{
                                    data.item.updated_at | filterDate
                                  }}</small>
                                </td>
                              </template>
                            </CDataTable>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="border-primary px-0 m-2 rounded"
                      v-if="infraction && infraction.type && !loading"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top"
                      >
                        <p class="mb-0 ">Información de la infracción</p>
                        <!--                        <div class="d-flex align-items-center">-->
                        <!--                          <div class="d-flex align-items-center">-->
                        <!--                            <p class="mb-0 mr-2">Editar</p>-->
                        <!--                            <CButton-->
                        <!--                              color="secondary"-->
                        <!--                              size="sm"-->
                        <!--                              class="d-flex align-items-center"-->
                        <!--                              @click="editFormInfraction"-->
                        <!--                            >-->
                        <!--                              <font-awesome-icon icon="edit" class="mr-1"/>-->
                        <!--                              <span v-if="editInfraction">Guardar</span>-->
                        <!--                              <span v-else>Editar</span>-->
                        <!--                            </CButton>-->
                        <!--                          </div>-->
                        <!--                        </div>-->
                      </div>
                      <div class="p-3">
                        <p
                          class="mb-1 d-flex align-items-center justify-content-between font-weight-bold"
                        >
                          Artículo:
                          <c-input
                            v-model="infraction.type.article_number"
                            v-if="editInfraction"
                            class="w-25"
                          />
                          <span class="text-primary" v-else>{{
                            infraction.type.article_number
                          }}</span>
                        </p>
                        <p
                          class="mb-1 d-flex align-items-center justify-content-between font-weight-bold"
                        >
                          <!--                          Ley: <span class="text-primary">{{infraction.type.law_number}}</span>-->
                          Ley:
                          <c-input
                            v-model="infraction.type.law_number"
                            v-if="editInfraction"
                            class="w-25"
                          />
                          <span class="text-primary" v-else>{{
                            infraction.type.law_number
                          }}</span>
                        </p>
                        <p
                          class="mb-1 d-flex align-items-center justify-content-between font-weight-bold"
                        >
                          <!--                          Descripción: <span class="text-primary">{{infraction.type.name}}</span>-->
                          Descripción:
                          <c-input
                            v-model="infraction.type.name"
                            v-if="editInfraction"
                            class="w-25"
                          />
                          <span class="text-primary" v-else>{{
                            infraction.type.name
                          }}</span>
                        </p>
                        <hr
                          class="my-2"
                          v-if="infraction.infraction_infractor"
                        />
                        <p
                          class="mb-1 d-flex align-items-center justify-content-between font-weight-bold"
                          v-if="infraction.infraction_infractor"
                        >
                          <!--                          Datos del dueño: <span class="text-primary">{{infraction.infraction_infractor.name-->
                          <!--                          }} {{infraction.infraction_infractor.lastname }}</span>-->
                          Datos del dueño:
                          <c-input
                            v-model="infraction.infraction_infractor.name"
                            v-if="editInfraction"
                            class="w-25"
                          />
                          <span class="text-primary" v-else>{{
                            infraction.infraction_infractor.name
                          }}</span>

                          <c-input
                            v-model="infraction.infraction_infractor.lastname"
                            v-if="editInfraction"
                            class="w-25"
                          />
                          <span class="text-primary" v-else>{{
                            infraction.infraction_infractor.lastname
                          }}</span>
                        </p>
                        <p
                          class="mb-1 d-flex align-items-center justify-content-between font-weight-bold"
                          v-if="infraction.infraction_infractor"
                        >
                          <!--                          RUT: <span class="text-primary">{{rutFormat}}</span>-->
                          RUT:
                          <c-input
                            v-model="infraction.infraction_infractor.rut"
                            v-if="editInfraction"
                            class="w-25"
                          />
                          <span class="text-primary" v-else>{{
                            infraction.infraction_infractor.rut
                          }}</span>
                        </p>
                        <p
                          class="mb-1 d-flex align-items-center justify-content-between font-weight-bold"
                          v-if="infraction.infraction_infractor"
                        >
                          <!--                          Dirección: <span class="text-primary">{{infraction.infraction_infractor.full_address}}</span>-->
                          Dirección:
                          <c-input
                            v-model="
                              infraction.infraction_infractor.full_address
                            "
                            v-if="editInfraction"
                            class="w-25"
                          />
                          <span class="text-primary" v-else>{{
                            infraction.infraction_infractor.full_address
                          }}</span>
                        </p>
                        <p
                          class="mb-1 d-flex align-items-center justify-content-between font-weight-bold"
                          v-if="infraction.infraction_infractor"
                        >
                          <!--                          Teléfono: <span class="text-primary">{{infraction.infraction_infractor.phone}}</span>-->
                          Teléfono:
                          <c-input
                            v-model="infraction.infraction_infractor.phone"
                            v-if="editInfraction"
                            class="w-25"
                          />
                          <span class="text-primary" v-else>{{
                            infraction.infraction_infractor.phone
                          }}</span>
                        </p>
                        <hr class="my-2" v-if="infraction.infraction_vehicle" />
                        <p
                          class="mb-1 d-flex align-items-center justify-content-between font-weight-bold"
                          v-if="infraction.infraction_vehicle"
                        >
                          <!--                          Patente: <span class="text-primary">{{infraction.infraction_vehicle.ppu}}</span>-->
                          Patente:
                          <c-input
                            v-model="infraction.infraction_vehicle.ppu"
                            v-if="editInfraction"
                            class="w-25"
                          />
                          <span class="text-primary" v-else>{{
                            infraction.infraction_vehicle.ppu
                          }}</span>
                        </p>
                        <p
                          class="mb-1 d-flex align-items-center justify-content-between font-weight-bold"
                          v-if="infraction.infraction_vehicle"
                        >
                          <!--                          Marca: <span class="text-primary">{{infraction.infraction_vehicle.brand}}</span>-->
                          Marca:
                          <c-input
                            v-model="infraction.infraction_vehicle.brand"
                            v-if="editInfraction"
                            class="w-25"
                          />
                          <span class="text-primary" v-else>{{
                            infraction.infraction_vehicle.brand
                          }}</span>
                        </p>
                        <p
                          class="mb-1 d-flex align-items-center justify-content-between font-weight-bold"
                          v-if="infraction.infraction_vehicle"
                        >
                          <!--                          Modelo: <span class="text-primary">{{infraction.infraction_vehicle.model}}</span>-->
                          Modelo:
                          <c-input
                            v-model="infraction.infraction_vehicle.model"
                            v-if="editInfraction"
                            class="w-25"
                          />
                          <span class="text-primary" v-else>{{
                            infraction.infraction_vehicle.model
                          }}</span>
                        </p>
                        <p
                          class="mb-1 d-flex align-items-center justify-content-between font-weight-bold"
                          v-if="infraction.infraction_vehicle"
                        >
                          <!--                          Color: <span class="text-primary">{{infraction.infraction_vehicle.color}}</span>-->
                          Color:
                          <c-input
                            v-model="infraction.infraction_vehicle.color"
                            v-if="editInfraction"
                            class="w-25"
                          />
                          <span class="text-primary" v-else>{{
                            infraction.infraction_vehicle.color
                          }}</span>
                        </p>
                      </div>
                    </div>
                  </CCol>
                  <CCol col="12" xl="8">
                    <div class="border-primary px-0 m-2 rounded">
                      <div
                        class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top"
                      >
                        <p class="mb-0 ">Grabación de la denuncia</p>
                      </div>
                      <CRow class="p-3">
                        <CCol class="d-flex" col="12" xl="12">
                          <video
                            width="100%"
                            style="max-height: 400px;"
                            v-if="
                              $store.state.showingComplaint.videorecord &&
                                $store.state.showingComplaint.videorecord
                                  .recording_link
                            "
                            :src="
                              $store.state.showingComplaint.videorecord
                                .recording_link
                            "
                            controls
                          ></video>
                          <div v-else>
                            Lo sentimos, la grabación de esta denuncia no se
                            encuentra disponible
                          </div>
                        </CCol>
                      </CRow>
                    </div>
                    <div class="border-primary px-0 m-2 rounded">
                      <div
                        class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top"
                      >
                        <p class="mb-0 ">Cambiar estado</p>
                      </div>
                      <CRow class="p-3">
						<VCol class="col-4 col-xl-4">

						<label>Visualizar en panel público</label>
                        <CSwitch
						v-if="!loading"
						alignVertical="center"
						alignHorizontal="center"
						  variant="opposite"
                          color="dark"
                          :checked.sync="viewOnPanel"
						  shape="pill"
						  class="ml-3 switch"
                        />
						 <CSpinner style="margin-left: 30px" v-else color="warning" size="sm"/>
						</VCol>
                      </CRow>
                      <CRow class="p-3">
                        <CCol col="4" xl="4">
                          <p class="mb-0 ">Nuevo Estado</p>
                          <v-select
                            class="mt-2 mb-0"
                            style="min-width: 150px"
                            :clearable="false"
                            :searchable="false"
                            :options="statuses"
                            :reduce="(option) => option.id"
                            :selectable="
                              (option) => option.id > statuschange.new_status
                            "
                            v-model="statuschange.new_status"
                          ></v-select>
                        </CCol>
                        <CCol col="8" xl="8">
                          <p class="mb-0 ">Detalle</p>
                          <CTextarea
                            class="mt-2"
                            placeholder="Detalle"
                            v-model="statuschange.details"
                          />
                        </CCol>
                        <CCol col="12" xl="12">
                          <CButton
                            :disabled="
                              loading ||
                                statuschange.new_status <=
                                  $store.state.showingComplaint.status[0]
                                    .status_id
                            "
                            color="primary"
                            class="my-1"
                            @click="changeStatus"
                            block
                            size="sm"
                          >
                            Cambiar Estado
                          </CButton>
                        </CCol>
                      </CRow>
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="12" xl="12">
                    <div
                      style="border-top-color: #0064ed!important;"
                      class="w-100 px-3 py-2 border-top d-flex justify-content-end"
                    >
                      <CButton @click="closeModal()" color="success">
                        <img
                          style="margin-right: 5px;"
                          class="white-color-image-overlay"
                          height="12"
                          :src="
                            require('@/assets/imgicons/ICONOS SOSRecurso 19@2x.png')
                          "
                        />
                        Cerrar
                      </CButton>
                    </div>
                  </CCol>
                </CRow>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="closeModal" color="primary" size="lg">Salir</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { changeComplaintStatus, getComplaintById,  markComplaintAsPublic} from "@/api/complaints";
import { format } from "rut.js";
export default {
  name: "ModalComplaint",
  filters: {
    filterDate: function(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
  },
  components: { "v-select": vSelect },
  computed: {
    rutFormat: function() {
      return format(this.infraction.infraction_infractor.id_code);
    },
  },
  data() {
    return {
      loading: false,
      statuschange: {
        complaint_id: this.$store.state.showingComplaint.id,
        new_status: this.$store.state.showingComplaint.status[0].status_id,
        details: "",
      },
      statuses: [
        { id: 1, label: "Ingresada" },
        { id: 2, label: "Visualizada" },
        { id: 3, label: "En trámite" },
        { id: 4, label: "Cancelada" },
        { id: 5, label: "Finalizada" },
      ],
      editInfraction: false,
      infraction: {},
	  viewOnPanel: this.$store.state.showingComplaint.is_public_count,
    };
  },
  mounted() {
	console.log('a',this.$store.state.showingComplaint);
    if (this.$store.state.showingComplaint.type.title == "Infracción") {
      this.getInfraction();
    }
  },
  methods: {
    editFormInfraction() {
      this.editInfraction = !this.editInfraction;
    },
    async getInfraction() {
      this.loading = true;
      try {
        const response = await getComplaintById({
          complaint_id: this.$store.state.showingComplaint.unique_id,
        });
        this.infraction = response.complaint.infraction;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async changeStatus() {
      this.loading = true;
      const statusChange = await changeComplaintStatus(this.statuschange);
      if (statusChange.code === 200) {
        this.$store.commit("setShowingComplaint", statusChange.complaint);
        this.$events.fire("reloadComplaints");
        this.statuschange.details = "";
      }
      this.loading = false;
    },
    returnIcon(marker) {
      if (marker.type && marker.type.base_type) {
        return marker.type.base_type.marker_icon;
      } else if (marker.type) {
        return marker.type.marker_icon;
      }
    },
    closeModal() {
      this.$store.commit("setShowingComplaint", null);
    },
    call(phoneToCall, name) {
      const self = this;
      const callData = {
        phoneToCall,
        name,
      };
      this.$store.commit("setCallData", callData);
    },
    getBadge(status) {
      const statusMap = [
        "primary",
        "warning",
        "success",
        "danger",
        "primary",
        "info",
      ];
      return statusMap[status - 1];
    },
	setViewOnPanel(data) {
		markComplaintAsPublic(data).then((res) => {
	  console.log(res);
	})

	}
  },
    watch: {
    viewOnPanel: function (value) {
		const data = {
			company_id: this.$store.state.showingComplaint.company_id,
			complaint_id: this.$store.state.showingComplaint.id,
			status: value
		}
		this.setViewOnPanel(data)
		this.loading = true;
		setTimeout(() => {
			this.loading = false;			
		}, 1000);
    }
}
}

</script>

<style type="text/css" media="screen">
.select-cierre-tipo .vs__search::placeholder,
.select-cierre-tipo .vs__dropdown-toggle,
.select-cierre-tipo .vs__dropdown-menu,
.select-cierre-tipo .vs__open-indicator {
  color: #d0282e;
  border-color: #d0282e;
}
.switch {
	vertical-align: middle!important;
}
.c-switch-slider {
    border: 1px solid rgb(49, 60, 78)!important;
}
.c-switch-slider::before {
    border: 1px solid rgb(49, 60, 78)!important;
}
.c-switch-opposite-dark .c-switch-input:checked + .c-switch-slider::before {
    background-color: #c8ff2c;
}
</style>
